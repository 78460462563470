import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MancareaPage = () => (
  <Layout>
    <SEO title="Mâncarea de la nuntă" />
    <div className="drawer article-content">
      <h1 className="article-title">Mâncarea de la nuntă</h1>

      <section>
        <p>
        Când organizezi nunta sunt așa de multe de luat în calcul, încât ajungi să nu îți mai poți da seama ce este mai important și din acest motiv, cea mai bună soluție este să consideri fiecare aspect important și să iei în calcul atât dorințele voastre, dar să încerci să te gândești și la invitați.

        </p>
        <p>
        Voi poate nici nu veți reuși să mâncați din preparatele de la nuntă sau poate vă doriți o nuntă la care oamenii să danseze tot timpul și nu să stea jos și să mănânce.
   </p>
        
      </section>

      <img src="https://images.unsplash.com/photo-1580682197386-8746a263c370?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80" />

      <h3 className="paragh-title">
      Așadar, meniul clasic sau bufet suedez?
      </h3>
      <section>
        <p>Principalele aspecte pe care trebuie să le luați în calcul când faceți această alegere sunt numărul de invitați și vârsta acestora.</p>
        <p>Pentru o nuntă mică, doar cu prietenii apropiați și famila, bufetul suedez este o opțiune foarte bună.
</p>
<p>
Dacă aveți un număr mare de invitați, de toate vârstele, cel mai bine este să optați pentru servirea la masă, astfel riști să se creeze cozi imense, unii oameni să nu meargă să se servească pentru că le este rușine etc.

</p>
      </section>

      <h3 className="paragh-title">
      Meniul clasic tradițional sau altceva?

      </h3>
      <section>
        <p>Cea mai întânită variantă de meniu conține antreu/aperitiv, pește, sarmale și friptură.</p>
        <p>Din nou, dacă vă gândiți să optați pentru un meniu cu preparate mai deosebite, nu uitați să vă gândiți și la restul invitaților, respectiv la media de vârstă și să vă asigurați că cei de restaurant sau un alt bucătar pe care îl angajați știe pregătească bine ceea ce vă doriți voi. </p>
        <p>În cazul unui meniu tradițional, îl puteți modifica oarecum după dorințele voastre și totuși să păstrați linia clasică. Puteți rămâne la 3 feluri de mâncare sau puteți opta pentru un alt tip de pește, iar la aperitiv puteți include și mânăruri mai deosebite, clasic nu înseamnă să serviți ardei umplut cu icre, roșie cu vânătă și păstrăv.</p>
        
        <p className="paragh-title">Apecte de luat în calcul:
</p>
        <ul>
          <li>Trebuie neapărat să mergeți la degustarea meniului
</li>
          <li>Bazați-vă pe calitate. Oricum sunt deja multe feluri de mâncare și cu siguranță nimeni nu reușește să mănânce tot ce veți servi, așadar bazați-vă pe calitate.</li>
          <li>La aperitiv, pe lângă calitate, este important ca farfuria să nu fie totuși goală. Alocați eventual un buget mai mare. Trebuie să fie divers, să conțină mai multe elemente, astfel încât invitații să aibă opțiuni. Nu uita că este primul contact al invitaților cu meniul vostru și este foarte posibil ca invitaților să le fie foame.
</li>
          <li>Pauzele dintre felurile de mâncare sunt cam de 2h. Gandește-te la acest lucru luând în calcul și ora de început și numărul de feluri, nu ai vrea să servești tortul la 6 dimineața.
</li>
          <li>Gândește-te și la opțiuni de meniuri pentru copii și pentru vegani </li>
          <li>La pește poți adăga 2 garnituri diferite (ex.legume sote și medalion de legume)
</li>
          <li>La friptura de obicei se pun 2 variante diferite, cel mai adesea pui și porc. Poți alege și vită și rață, însă din nou, gândește-te dacă și invitații ar fi încântați și dacă bucătarul le gătește fix cum ar trebui. Friptura/felul principal se servește de obicei cu două garnituri și cu o salată.
</li>
          <li>Dacă alegi să renunți la meniul tradițional mergi pe linia aleasă tot evenimentul și încearcă să încadrezi meniul ales în povestea nunții. Nu este o idee bună să servești aperitiv clasic, apoi mâncare chinezească, ulterior fructe de mare și la sfârșit să alegi și niște sarmale.
</li>
<li>
Nu uita să îi iei în calcul și pe furnizorii de servicii la numărul de meniuri comandate: formația/dj-ul, fotograful, cameramanul etc.

</li>
<li>
Discută toate aspectele cu cei de la restaurant/sala de evenimente. Dacă ei se ocupă de mâncare, dacă apelează la catering (aici trebuie să te asiguri că mâncarea nu va ajunge rece). Discută și de când trebuie să transmiți numărul final de meniuri și câte pot asigura în plus, dacă ai estimat greșit numărul de invitați etc.

</li>
        </ul>
      </section>


      <section>
        <p>
        Chiar dacă voi nu veți reuși să vă delectați cu mâncarea aleasă de voi (să nu uitați totuși să mâncați ceva în ziua nunții), gândiți-vă per ansamblu la invitați și la cum ați vrea să arate nunta voastră și din acest punct de vedere.

        </p>
      </section>

      <div className="nav-section">
        <Link to="/marturiile">◀ Mărturiile </Link>
        <Link to="/guestBook">▶ Guest Book/ Cartea de oaspeți</Link>
      </div>
    </div>
  </Layout>
)

export default MancareaPage
